@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600;700&display=swap');

html,
body{
  height: 100vh;
}
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'source sans pro', sans-serif;
}

.app {
  display: flex;
  position: relative;
  height: auto;
}

/* Scroll bar settings */
::-webkit-scrollbar {
  width: 10px;
}

/* Track styling */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle styling */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}